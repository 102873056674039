import { AgnosticTaxonomy } from 'shared/types'

export function useTaxonomy() {
  const $taxonomy = useState<AgnosticTaxonomy[]>('pageTaxonomy', () => [])

  // function getNotLocalizedTaxonomyById(id: string, taxonomy?: AgnosticTaxonomy[]): AgnosticTaxonomy | undefined {
  //   for (const category of taxonomy || $taxonomy.value) {
  //     if (category.id === id) {
  //       return category
  //     }

  //     const result = getNotLocalizedTaxonomyById(id, category.items)

  //     if (result) {
  //       return result
  //     }
  //   }

  //   return undefined
  // }

  return {
    // getNotLocalizedTaxonomyById,
    taxonomy: computed(() => $taxonomy.value)
  }
}
