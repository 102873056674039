<template>
  <li
    v-bind="$attrs"
    :id="value.toString()"
    role="option"
    class="asf-dropdown__option"
    tabindex="-1"
    :class="{ 'is-active': selectedValue === value }"
    :aria-selected="ariaSelected"
  >
    <slot />
  </li>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { AsfDropdownOption } from '@ui/types'

export default defineComponent({
  name: 'AsfDropdownOption',
  props: {
    selectedValue: { type: String as PropType<AsfDropdownOption['value']>, default: '' },
    value: { type: String as PropType<AsfDropdownOption['value']>, default: '' }
  },
  setup(props) {
    return {
      ariaSelected: computed(() => props.selectedValue === props.value)
    }
  }
})
</script>
