<template>
  <header
    id="page-head"
    v-e2e="'page-head'"
    class="asf-header"
    role="banner"
    :class="{ 'is-sticky-mobile': isStuck && isSmallOrMediumView }"
  >
    <div
      ref="headerRef"
      v-e2e="isStuck && isSmallOrMediumView ? 'mobile-sticky-header' : 'mobile-global-header'"
      class="asf-header__inner"
    >
      <AsfWrapper type="header" class="asf-header__wrapper">
        <div class="asf-header__left asf-header__actions">
          <!-- @slot Use this slot to replace mobile menu button selector -->
          <slot name="content-hamburger">
            <AsfButton
              ref="hamburgerButton"
              v-e2e="'hamburger-menu'"
              class="asf-header__actions__item m-hamburger"
              :aria-label="`${$t('header.menu')}`"
              data-keep-focus-highlighter
              @keydown="handleHamburgerKeydown"
              @click="openMobileMenu"
            >
              <AsfIcon name="hamburger" size="7" />
            </AsfButton>
          </slot>
          <!-- @slot Use this slot to replace language selector -->
          <slot name="content-locale" />
        </div>
        <div class="asf-header__middle">
          <slot name="content-logo">
            <AsfLogo :link="headerLinks && headerLinks.logo" :title="title" v-e2e="'header-logo'" />
          </slot>
        </div>
        <div class="asf-header__right">
          <div class="asf-header__actions">
            <!-- @slot Use this slot to replace the search panel-->
            <slot name="content-search-icon">
              <div role="search">
                <AsfButton
                  v-e2e="'search-button'"
                  class="asf-header__actions__item m-search"
                  aria-haspopup="dialog"
                  :aria-label="`${$t('search.word')}`"
                  :title="`${$t('search.word')}`"
                  data-keep-focus-highlighter
                  @click="openSearch"
                >
                  <AsfIcon name="search" />
                  <span class="asf-header__search_title">{{ $t('search.word') }}</span>
                </AsfButton>
              </div>
            </slot>
            <!-- @slot Use this slot to replace my account icon with text or image-->
            <slot name="content-user-icon">
              <AsfLink
                v-e2e="'my-account-icon'"
                class="asf-header__actions__item m-user"
                :link="headerLinks && headerLinks.account"
                :title="$t(isLoggedIn ? 'header.myAccount' : 'header.signin')"
                :aria-label="$t(isLoggedIn ? 'header.myAccount' : 'header.signin')"
              >
                <AsfIcon name="user" />
              </AsfLink>
            </slot>
            <!-- @slot Use this slot to replace wishlist icon with text or image-->
            <slot name="content-wishlist-icon" :wishlist-total="wishlistTotal" :open-wishlist="openWishlist" />
            <!-- @slot Use this slot to replace mini-cart icon with text or image-->
            <slot name="content-mini-cart-icon">
              <AsfLink
                v-e2e="'mini-cart-icon'"
                class="asf-header__actions__item m-cart"
                :title="$t('header.minicart')"
                :aria-label="cartTotalMassage"
                :link="headerLinks && headerLinks.cart"
              >
                <AsfIcon id="minicart" name="shopping-bag" />
                <client-only>
                  <AsfBadge v-if="!!cartTotal" appearance="circle">
                    {{ cartTotal }}
                  </AsfBadge>
                </client-only>
              </AsfLink>
            </slot>
          </div>
        </div>
      </AsfWrapper>
    </div>
  </header>
</template>
<script lang="ts">
import throttle from 'lodash.throttle'
import { AsfHeaderProps, AsfKeyValue } from '@ui/types'
import type { AsfButton } from '#components'

export default defineComponent({
  name: 'AsfHeader',
  emits: ['open:search', 'open:mobileMenu', 'open:wishlist'],
  props: {
    headerLinks: { type: Object as PropType<AsfHeaderProps['headerLinks']>, required: true },
    title: { type: String as PropType<AsfHeaderProps['title']>, default: '' },
    cartTotal: { type: Number as PropType<AsfHeaderProps['cartTotal']>, default: 0 },
    wishlistTotal: { type: Number as PropType<AsfHeaderProps['wishlistTotal']>, default: 0 },
    isSticky: { type: Boolean as PropType<AsfHeaderProps['isSticky']>, default: true },
    isLoggedIn: { type: Boolean as PropType<AsfHeaderProps['isLoggedIn']>, default: false }
  },
  setup(props: AsfHeaderProps, { emit }) {
    const headerRef = ref<Element | null>(null)
    const hamburgerButton = ref<InstanceType<typeof AsfButton> | null>(null)
    const isStuck = ref(false)
    const elementPosition = ref<ReturnType<typeof getElementCoordinates>>({ top: 0, left: 0 })
    const { isMediaMatch: isSmallOrMediumView } = useMediaQuery()
    const { t } = useI18n()
    const cartTotalMassage = computed(() => {
      if (!props.cartTotal) {
        return t('header.minicartAriaLabel', 0)
      }

      // @ts-ignore
      return t('header.minicartAriaLabel', props.cartTotal, { total: props.cartTotal })
    })

    const scrollHandler = () => {
      if (process.client) {
        const currentScrollPosition = window.scrollY || document.documentElement.scrollTop
        if (elementPosition.value) {
          isStuck.value = currentScrollPosition >= elementPosition.value.top
        }
      }
    }
    const openMobileMenu = () => emit('open:mobileMenu', true)
    const openSearch = () => emit('open:search', true)
    const openWishlist = () => emit('open:wishlist', true)
    const handleHamburgerKeydown = (event: KeyboardEvent) => {
      let preventDef = false

      switch (event.key) {
        case AsfKeyValue.DOWN:
        case AsfKeyValue.ENTER:
        case AsfKeyValue.SPACE:
          preventDef = true
          openMobileMenu()
          break

        default:
          break
      }

      preventDef && event.preventDefault()
    }
    const focusHamburgerButtonOnMenuClose = () => {
      if (hamburgerButton.value) {
        // eslint-disable-next-line prettier/prettier
        (hamburgerButton.value.$el as HTMLButtonElement).focus()
      }
    }

    let stopScrollHandler: ReturnType<typeof addEventListenerUtil> | null = null
    onMounted(() => {
      EventBus.on('hamburger.focus', focusHamburgerButtonOnMenuClose)

      if (props.isSticky && process.client) {
        elementPosition.value = getElementCoordinates(headerRef.value as Element)

        const handler = throttle(scrollHandler, 50)
        stopScrollHandler = addEventListenerUtil({ type: 'scroll', handler })
      }
    })

    onBeforeUnmount(() => {
      EventBus.off('hamburger.focus', focusHamburgerButtonOnMenuClose)

      if (stopScrollHandler) {
        stopScrollHandler()
      }
    })

    return {
      cartTotalMassage,
      hamburgerButton,
      handleHamburgerKeydown,
      headerRef,
      isStuck,
      isSmallOrMediumView,
      openMobileMenu,
      openSearch,
      openWishlist
    }
  }
})
</script>
<style lang="postcss">
@import '@components/organisms/Header/Header.css';
</style>
