<template>
  <div
    v-e2e="`promo-bar-${modifier}`"
    :aria-label="`${$t('accessibility.group.common.promoLine')}`"
    role="note"
    class="asf-promo-bar"
    :class="modifier"
  >
    <AsfWrapper :type="wrapperType" class="asf-promo-bar__inner">
      <slot />
    </AsfWrapper>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { AsfPromoBarProps, AsfWrapperType } from '@ui/types'

export default defineComponent({
  name: 'AsfPromoBar',
  props: {
    /**
     * Defines functional type of the component
     * @values top, bottom
     */
    appearance: { type: String as PropType<AsfPromoBarProps['appearance']>, default: 'top' }
  },
  setup(props: AsfPromoBarProps) {
    const wrapperType = computed<AsfWrapperType>(() => {
      return props.appearance === 'top' ? 'header' : 'section'
    })

    const modifier = computed(() => `m-${props.appearance}`)

    return { wrapperType, modifier }
  }
})
</script>
<style lang="postcss">
@import '@components/molecules/PromoBar/PromoBar.css';
</style>
