<template>
  <AsfDropdown
    v-e2e="'locale-selector'"
    class="asf-locale-selector"
    :items="locales.items"
    :selected="selectedItem"
    :label="selectorLabel"
    :show-label="false"
    @update:selected="handleKeyDownChange"
  >
    <template #content-selected="{ item }">
      <AsfIcon :name="item.iconName" :size="['8', '4']" />
      <span class="asf-locale-selector__lang">{{ item.label }}</span>
    </template>

    <template v-slot="{ item }">
      <AsfLink v-e2e="`locale-item-${item.id}`" :link="item.link">
        <AsfIcon :name="item.iconName" :size="['8', '4']" />
        <span class="asf-locale-selector__lang">{{ item.label }}</span>
      </AsfLink>
    </template>
  </AsfDropdown>
</template>

<script lang="ts" setup>
import { AsfLocaleObject } from '@ui/types'

type LocaleSelectorProps = {
  locales: AsfLocaleObject
}

const props = defineProps<LocaleSelectorProps>()

const emit = defineEmits(['changeLocale'])
const { t } = useI18n()
const selectedItem = computed<AsfLocaleObject>(() => props.locales)
const selectorLabel = computed(() => t('locale.selectorLabel', { locale: selectedItem.value.label }))

watch(selectedItem, (value) => {
  if (!value) return null

  EventBus.emit('disable:highlighter')
})

const handleKeyDownChange = (item: AsfLocaleObject) => {
  emit('changeLocale', item)
}
</script>
<style lang="postcss">
@import 'assets/css/components/header/locale-selector.css';
</style>
