<template>
  <div class="app-search">
    <AsfSearchPanel
      :visible="isSearchOpen"
      :loading="suggestionLoading"
      :result="suggestions"
      :is-response="isSuggestion"
      @search:close="toggleSearchPanel"
      @search:submit="handleSearchSubmit"
      @search:change="handleSearchChange"
      @search:redirect="handleSearchRedirect"
      @product:clicked="handleProductClick"
    />
  </div>
</template>
<script lang="ts" setup>
import { AsfSearchPanelProps } from '@ui/types'
import { AgnosticPriceValue, AgnosticProduct } from 'shared/types'
const { $preferences } = useNuxtApp()
const { getProductURL, getURL } = useURL()
const { toggleSearch, isSearchOpen } = useUiState()
const { loading: suggestionLoading, result: suggestionResult, suggestionSearch } = useSearch('suggestions')
const { reportProductClick, reportProductImpressions } = useTagManager()
const isSuggestion = ref(false)
const products = computed(() => suggestionResult.value?.products || [])

const suggestions = computed<AsfSearchPanelProps['result']>(() => {
  if (!isSuggestion.value) {
    try {
      const initialSuggestions = JSON.parse($preferences.initialSearchSuggestions)

      return {
        suggestions: [
          {
            type: 'initial',
            title: initialSuggestions.title,
            data: initialSuggestions.links.map((link: any) => ({
              label: link.text,
              slug: link.url
            }))
          }
        ]
      }
    } catch (err) {
      return { suggestions: [] }
    }
  }

  if (products.value.length) {
    reportProductImpressions(products.value, 'Search Suggestions')

    return {
      suggestions: [
        {
          type: 'product',
          data: products.value.map((product) => {
            const prices: AgnosticPriceValue[] = getProductPrices(product)
            const image = product.images.at(0)

            return {
              slug: getProductURL(product),
              label: product.name,
              prices,
              image,
              product
            }
          })
        }
      ]
    }
  }

  return {}
})

const toggleSearchPanel = () => {
  isSuggestion.value = false
  toggleSearch()
}

const handleSearchSubmit = (phrase: string) => {
  toggleSearch()

  const suggestedProducts = (suggestions.value.suggestions || [])
    .filter((suggestion) => suggestion.type === 'product')
    .flatMap((suggestion) => suggestion.data)

  if (suggestedProducts.length === 1 && suggestedProducts[0]) {
    navigateTo(suggestedProducts[0].slug)
    return
  }

  navigateTo(getURL({ name: 'search', query: { q: phrase } }))
}

const handleSearchRedirect = (url: string) => {
  toggleSearch()
  navigateTo(url)
}

const handleSearchChange = async (phrase: string) => {
  if (!phrase) {
    isSuggestion.value = false
    return
  }

  isSuggestion.value = true
  await suggestionSearch({ itemsPerPage: 3, q: phrase })
}

const handleProductClick = (payload: { product: AgnosticProduct; position: number }) => {
  reportProductClick(payload.product, 'Search Suggestions', { position: payload.position })
}
</script>
