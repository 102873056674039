<template>
  <div class="asf-user-nav">
    <AsfSidebarSlidingItem
      v-for="{ id, title, iconName, link } in items"
      :id="id"
      :key="id"
      v-e2e="`account-navigation-item`"
      :label="title"
      :link="link"
      :tabindex="tabindex"
      class="asf-user-nav__link"
      @click="$emit('click:item')"
    >
      <template #content-icon>
        <AsfIcon v-if="iconName" :name="iconName" class="asf-user-nav__icon" />
      </template>
    </AsfSidebarSlidingItem>
  </div>
</template>
<script lang="ts">
import { computed, ComputedRef, defineComponent, PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { RouteLocationRaw } from 'vue-router'
import { AsfIconProps } from '@ui/types'

type UserNavigationProps = {
  isAuthenticated?: boolean
  tabindex?: string | number
}
type UserNavigationItem = {
  id: string
  title: TranslateResult
  link: RouteLocationRaw
  iconName?: AsfIconProps['name']
}

export default defineComponent({
  name: 'UserNavigation',
  props: {
    isAuthenticated: { type: Boolean as PropType<UserNavigationProps['isAuthenticated']>, required: true },
    tabindex: { type: [String, Number] as PropType<UserNavigationProps['tabindex']>, default: '0' }
  },
  setup(props: UserNavigationProps) {
    const { t } = useI18n()
    const { getURL } = useURL()
    const items: ComputedRef<UserNavigationItem[]> = computed(() => [
      {
        id: 'user',
        title: props.isAuthenticated ? t('header.accountOverview') : t('header.signinRegister'),
        // @ts-expect-error
        link: props.isAuthenticated ? getURL({ name: 'account' }) : getURL({ name: 'login' }),
        iconName: 'user'
      }
    ])
    return { items }
  }
})
</script>
<style lang="postcss">
@import 'assets/css/components/header/user-navigation.css';
</style>
