import { AgnosticTaxonomy } from 'shared/types'

export const createViewAllLink = (category: AgnosticTaxonomy, label: string) => {
  return {
    slug: category.slug,
    id: `${category.id}-view`,
    label,
    items: []
  }
}
