import { AsfMegaMenuProps } from '@ui/types'
import AsfMegaMenuItem from './internal/MegaMenuItem.vue'

export const OPEN_MENU_DELAY = 100

export const getAccessibleItems = (items: AsfMegaMenuProps['taxonomy']) => {
  return items.map((item) => item.slug)
}
export const getAllAccessibleItems = (items: AsfMegaMenuProps['taxonomy']) => {
  const result: string[] = []

  if (!items.length) {
    return result
  }

  items.forEach((item) => {
    result.push(item.id)

    if (item.items) {
      result.push(...getAllAccessibleItems(item.items))
    }
  })

  return result
}

export const getElementRefByID = (id: string, instance: ReturnType<typeof useCurrentInstance>) => {
  const ref = instance.$refs[id]
  if (Array.isArray(ref) && ref[0]) {
    return ref[0] as any as typeof AsfMegaMenuItem
  }
}

export const closeSubmenu = (items: readonly string[], instance: ReturnType<typeof useCurrentInstance>): void => {
  items.forEach((item) => {
    const currentItem = getElementRefByID(item, instance)
    // TODO: @i.khartov, fix error
    // @ts-ignore
    if (currentItem?.hasSubmenuOpen()) {
      // @ts-ignore
      currentItem?.closeMenu()
    }
  })
}
